.d-flex {
  display: flex;
}
.adilClass{
  background: red;
}
.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.EngagementProgramHeader--niveau {
  position: relative;
  background: #FBF5EA;
  height: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin: 6px;
  margin-left: 10px;
  padding: 0 15px;
  padding-left: 0;
}
.EngagementProgramHeader--niveau.message-error-maxing{
  font-weight: 700;
  padding: 0 7px 0 9px;
}
@media (min-width: 980px) {
  .EngagementProgramHeader--niveau {
    height: 36px;
    margin: 0 10px;
  }
}

.EngagementProgramHeader--xp {
  height: 100%;
  border-radius: 5px 0 0 5px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--xp {
    background: #EFE7D7;
    padding-right: 10px;
  }
}

.EngagementProgramHeader--xp label {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
  text-align: start;
  color: #000;
  width: fit-content;
  margin: 0;
  margin-right: 10px;
  padding-left: 15px;
  z-index: 999;
}

.EngagementProgramHeader--xp label.desktop-level {
  display: none;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--xp label.desktop-level {
    display: block;
  }
}

.EngagementProgramHeader--xp label.mob-level {
  display: block;
  margin-bottom: 16px;
  padding-left: 8px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--xp label.mob-level {
    display: none;
  }
}

.EngagementProgramHeader--xp .progress-container {
  display: none;
  width: 125px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 40px;
  border: 1px solid #DE9F99;
  overflow: hidden;
  z-index: 999;
}

.EngagementProgramHeader--xp .progress-container .progress-level {
  height: 18px;
  width: 10%;
  background-color: #E62814;
  border-radius: 40px 0 0 40px;
  border: 2px solid #FFF;
  transition: width 0.3s ease;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--xp .progress-container {
    display: block;
  }
}

.EngagementProgramHeader--lingot {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
  text-align: center;
  z-index: 999;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--lingot {
    display: block;
    margin-left: 15px;
  }
}

.EngagementProgramHeader--lingot img {
  margin-left: 0;
  width: 15px;
  height: 15px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--lingot img {
    margin-left: 10px;
    width: 19px;
    height: 12px;
  }
}

.EngagementProgramHeader--lingotIcon {
  margin-left: 10px;
  margin-top: -1px;
}

.EngagementProgramHeader--level-coefficient {
  position: absolute;
  height: 100%;
  right: 0;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--level-coefficient {
    width: 35%;
  }
}

.EngagementProgramHeader--level-coefficient .blurred-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 5px 5px 0;
}

.EngagementProgramHeader--level-coefficient .level-coefficient,
.EngagementProgramHeader--level-coefficient .lango-coefficient {
  position: absolute;
  background: #F2E7CE;
  top: -10px;
  opacity: 0;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
  border-radius: 4px;
  z-index: 999;
  color: #B48201;
  animation-name: toBottom;
  animation-delay: 2s;
  animation-duration: 1.5s;
}

.EngagementProgramHeader--level-coefficient .level-coefficient {
  right: 10%;
  padding: 2px 10px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--level-coefficient .level-coefficient {
    right: 40%;
    padding: 2px 14px;
  }
}

.EngagementProgramHeader--level-coefficient .lango-coefficient {
  right: 8px;
  padding: 2px 8px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--level-coefficient .lango-coefficient {
    right: 41px;
  }
}
.FullHeaderTop-item--horizonLogo-mobile .EngagementProgramHeader--level-coefficient .level-coefficient{
  right: -40px;
}
@keyframes toBottom {
  0% {
    top: -10px;
    opacity: 70%;
  }
  100% {
    top: 5px;
    opacity: 100%;
  }
}
.EngagementProgramHeader--status {
  position: relative;
  background: #FBF5EA;
  height: 45px;
  display: block;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin: 6px;
  padding: 5px 10px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--status {
    display: flex;
    padding: 0 15px;
    height: 36px;
    margin: 0;
  }
}

.EngagementProgramHeader--statusName {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  color: #000000;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--statusName {
    margin-right: 10px;
  }
}

.EngagementProgramHeader--statusLogo {
  margin-top: 4px;
  z-index: 9999;
}

.EngagementProgramHeader--statusLogo img {
  width: 20px;
  height: 20px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--statusLogo img {
    width: 25px;
    height: 25px;
  }
}

.EngagementProgramHeader--red-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-color: transparent;
  animation-name: redBg;
  animation-duration: 4s;
  opacity: 0;
}

.EngagementProgramHeader--red-bg.nextLevel {
  z-index: 999999;
}

.EngagementProgramHeader--red-bg.nextLevel p {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  margin-top: 8px;
}

@media (max-width: 680px) {
  .EngagementProgramHeader--red-bg.nextLevel p {
    margin-top: 13px;
  }
}

.EngagementProgramHeader--scintillement {
  position: absolute;
  top: -20px;
  left: 6px;
  width: 100%;
  height: 100%;
  transition: none;
}

.EngagementProgramHeader--scintillement img {
  position: absolute;
  opacity: 0;
  top: 10px;
  left: 0;
  animation-name: scintillement;
  transition: none;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--scintillement img {
    top: 0;
  }
}

.EngagementProgramHeader--scintillement-img1 {
  animation-delay: 0s;
  animation-duration: 1s;
}

.EngagementProgramHeader--scintillement-img2 {
  animation-delay: 0.9s;
  animation-duration: 1s;
}

.EngagementProgramHeader--scintillement-img3 {
  animation-delay: 2s;
  animation-duration: 2.5s;
}

.EngagementProgramHeader--scintillement.level img {
  right: 0;
  left: initial;
}

.EngagementProgramHeader--text-animation span {
  position: absolute;
  left: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  color: #FFFFFF;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--text-animation span {
    left: 15px;
  }
}

.EngagementProgramHeader--text-animation span.currentStatus {
  opacity: 0;
  bottom: 0;
  animation-name: bottomToTop;
  animation-delay: 0.5s;
  animation-duration: 1s;
}

.EngagementProgramHeader--text-animation span.nextStatus {
  top: 0;
  opacity: 0;
  animation-name: topToBottom;
  animation-delay: 1.5s;
  animation-duration: 2.5s;
}

@keyframes scintillement {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes redBg {
  0% {
    background-color: transparent;
    opacity: 0;
  }
  100% {
    background-color: #E62814;
    opacity: 1;
  }
}

@keyframes bottomToTop {
  0% {
    opacity: 0;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 10px;
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 10px;
  }
}
.infoBull--wrapper {
  position: absolute;
  width: 98%;
  height: 248px;
  bottom: 65px;
  background: white;
  z-index: 999999;
  text-align: center;
  display: none;
}

.infoBull--wrapper.show {
  display: block;
}

@media (min-width: 768px) { /* Remplacez 980px par sa valeur réelle si nécessaire */
  .infoBull--wrapper {
    width: 286px;
    bottom: -260px;
  }
}

.infoBull--logo {
  position: relative;
}

.infoBull--logo::before {
  content: "0";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  left: 50%;
  background: #fff;
  transform: rotate(45deg);
}

@media (min-width: 768px) { /* Remplacez 980px par sa valeur réelle si nécessaire */
  .infoBull--logo::before {
    content: "";
  }
}

.infoBull--desc p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  color: #000;
}

.infoBull--xp,
.infoBull--lango {
  background-color: #FBF5EA;
  padding: 5px 10px;
  border-radius: 5px;
}

.infoBull--xp span,
.infoBull--lango span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  color: #000;
  padding: 3px 6px;
  border-radius: 5px;
  background: #F2E7CE;
}
.EngagementProgramHeader--lango {
  position: relative;
  background: #FBF5EA;
  height: 45px;
  display: block;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin: 6px 10px;
  padding: 5px 15px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--lango {
    display: flex;
    height: 36px;
    margin: 0;
    padding: 0 15px;
  }
}

.EngagementProgramHeader--langoName {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  color: #000000;
  margin-right: 10px;
}

@media (min-width: 680px) {
  .EngagementProgramHeader--langoName {
    margin-right: 0;
  }
}

.EngagementProgramHeader--langoLogo {
  margin-top: 4px;
  z-index: 9999;
}

.EngagementProgramHeader--langoLogo-animation {
  position: absolute;
  top: 6px;
  left: 50px;
  z-index: -99;
  animation-name: showWhiteLogo;
  animation-delay: 0.9s;
  animation-duration: 3s;
}

.EngagementProgramHeader--langoLogo img {
  width: 20px;
  height: 20px;
}

@media (min-width: 980px) {
  .EngagementProgramHeader--langoLogo img {
    width: 25px;
    height: 25px;
  }
}

@keyframes showWhiteLogo {
  0% {
    z-index: -99;
  }
  100% {
    z-index: 999999;
  }
}
.programEquipementPage--wrapper.my-profile .error-message-maxxing{
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  p{
    margin-top: 18px;
  }
}
